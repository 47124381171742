<template>
	<div>

		<b-card
			v-if="fetchUserTags"
			no-body
		>
			<div class="m-2">

				<!-- Table Top -->
				<b-row>
					<!-- Search -->
					<b-col
						cols="12"
					>
						<div class="d-flex align-items-center justify-content-end">
							<b-button
								variant="primary"
								@click="showAddAccountTagModal()"
							>
								<span class="text-nowrap">
									<feather-icon icon="PlusIcon"/> {{ $t('New') }}
								</span>
							</b-button>
						</div>
					</b-col>
				</b-row>

			</div>
			<b-table
				ref="refUsersTagsListTable"
				class="position-relative table-white-space mb-0"
				:items="listUserTags"
				responsive
				:fields="tableColumns"
				primary-key="index"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No matching records found"
				:sort-desc.sync="isSortDirDesc"
			>
				<template #cell(index)="data">
					{{ perPage * (currentPage - 1) + data.index + 1 }}
				</template>

				<template #cell(tagType)="data">
					{{ data.item.tag_type ? data.item.tag_type.name : '-' }}
				</template>

				<template #cell(action)="data">
					<b-link
						v-if="$can('delete', 'usertag')"
						class="font-weight-bold text-nowrap"
						@click="deleteUserTag(data.item.id)"
					>
						<feather-icon icon="Trash2Icon" size="16"/>
					</b-link>
				</template>

				<template #cell(tags)="data">
					<!-- <b-badge
						v-for="tag in data.item.tag"
						:key="tag.id"
						variant="primary"
						class="mr-1"
						:style="`background-color: ${tag.color}`"
					>
						{{ tag.name }}
					</b-badge> -->
					<b-badge
						variant="primary"
						class="mr-1"
						:style="`background-color: ${data.item.tag.color}`"
					>
						{{ data.item.tag.name }}
					</b-badge>
				</template>

			</b-table>
			<div class="p-2">
				<b-row>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start"
					>
						<span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
					</b-col>
					<!-- Pagination -->
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>

						<b-pagination
							v-model="currentPage"
							:total-rows="totalUsersTags"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>

					</b-col>

				</b-row>
			</div>
		</b-card>

		<!-- Modal: Add Account Tag -->
		<add-account-tag-modal
			:tag-type-lists="tagTypeLists"
			@refetch-data="refetchData"
		/>
	</div>
</template>

<script>
import {
	BButton, BModal, VBModal,
	BCard, BRow, BCol, BFormInput, BTable, BTr, BTh, BMedia, BAvatar, BLink,
	BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ref, watch, computed, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import useUsersTag from '../tag/useUsersTag'
import tagStoreModule from '@/views/apps/user/tag/tagStoreModule'
import i18n from '@/libs/i18n'

export default {
	components: {
		AddAccountTagModal: () => import('./AddAccountTagModal.vue'),
		BButton,
		BModal,
		BCard,
		BRow,
		BCol,
		BFormInput,
		BTable,
		BTr,
		BTh,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		vSelect,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	props: {
		userData: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			tagId: null,
		}
	},
	methods: {
		showAddAccountTagModal() {
			this.$bvModal.show('modal-add-account-tag')
		},
		deleteUserTag(TagId) {
			this.$swal({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, delete it!',
				customClass: {
					confirmButton: 'btn btn-primary',
					cancelButton: 'btn btn-outline-danger ml-1',
				},
				buttonsStyling: false,
			}).then(result => {
				if (result.value) {
					const editData = {
						userId: router.currentRoute.params.id,
						userTagId: TagId,
					}
					store.dispatch('app-user/deleteUserTag', editData)
						.then(response => {
							if (response.data.code == '00') {
								this.$swal({
									icon: 'success',
									title: 'Deleted!',
									text: 'Your file has been deleted.',
									customClass: {
										confirmButton: 'btn btn-success',
									},
								})
								this.refetchData()
							}
						})
				}
			})
		},
	},
	setup() {

		if (!store.hasModule('app-user-tag')) store.registerModule('app-user-tag', tagStoreModule)
		// UnRegister on leave
		onUnmounted(() => {
			if (store.hasModule('app-user-tag')) store.unregisterModule('app-user-tag')
		})

		const { usersTags } = useUsersTag()

		const perPage = ref(20)
		const totalUsersTags = ref(0)
		const currentPage = ref(1)
		const perPageOptions = [10, 25, 50, 100]
		const sortBy = ref('id')
		const isSortDirDesc = ref(true)

		const tagTypeLists = ref(null)

		const refUsersTagsListTable = ref(null);
    const listUserTags = ref([]);

		const tableColumns = [
			{ key: 'index', label: '#' },
			{ key: 'tagType', label: i18n.t('Tag type') },
			{ key: 'tags', label: i18n.t('Tags') },
			{ key: 'action', label: i18n.t('Actions') }
		]

		const dataMeta = computed(() => {
			const localItemsCount = refUsersTagsListTable.value ? refUsersTagsListTable.value.localItems.length : 0
			return {
				from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
				to: perPage.value * (currentPage.value - 1) + localItemsCount,
				of: totalUsersTags.value,
			}
		})

    const refetchData = () => {
      refUsersTagsListTable.value.refresh();
      store.dispatch('app-user/fetchUserDetail', router.currentRoute.params.id)
          .then(response => {
            listUserTags.value = response.data.data.user.user_tags
            totalUsersTags.value = tagTypes ? tagTypes.length : 0;
          })
          .catch(error => {
            userData.value = undefined
          })
    }

		watch([currentPage, perPage], () => {
			refetchData()
		})

		const fetchUserTags = (ctx, callback) => {
      listUserTags.value = this.userData.user.user_tags
      totalUsersTags.value = tagTypes ? tagTypes.length : 0;
		}

		store.dispatch('app-user-tag/fetchTagTypes', {})
			.then(response => {
				const { tagTypes } = response
				tagTypeLists.value = tagTypes
			})

		return {
			refetchData,
			fetchUserTags,
			tableColumns,
			perPage,
			currentPage,
			totalUsersTags,
			dataMeta,
			perPageOptions,
			sortBy,
			isSortDirDesc,
			refUsersTagsListTable,
      listUserTags,
			usersTags,
			tagTypeLists,
		}
	},
}
</script>