// import { ref } from '@vue/composition-api'
export default function useUsersTag() {

	const usersTags = [
		{
			id: 1,
			tagType: 'Withdrawal',
			tags: [
				{
					id: 1,
					name: 'Withdrawal',
					description: 'KYC Withdrawal',
					color: '#3CC2BB',
				},
			],
			description: 'Veriry',
			createdAt: '03-18-2022 23:15:00',
			updatedAt: '03-18-2022 23:15:00',
		},
		{
			id: 2,
			tagType: 'Deposit',
			tags: [{
				id: 1,
				name: 'Advance Credit',
				description: 'KYC Credit',
				color: '#3CC2BB',
			}],
			description: 'Deposit',
			createdAt: '03-17-2022 19:16:25',
			updatedAt: '03-17-2022 19:16:25',
		},
		{
			id: 3,
			tagType: 'Compliance',
			tags: [],
			description: 'Complance',
			createdAt: '03-17-2022 18:25:19',
			updatedAt: '03-17-2022 18:25:19',
		},
	]
	return {
		usersTags,
	}
}